<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="authentication/business-operation-log-page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.operatorName','登陆人')">
            <el-input v-model.trim="filter.operatorName	" clearable></el-input>
          </vm-search>

          <vm-search label="操作时间起">
            <el-date-picker
              v-model.trim="filter.operatedTimeFrom"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable></el-date-picker>
          </vm-search>
          <vm-search label="操作时间止">
            <el-date-picker
              v-model.trim="filter.operatedTimeTo"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable></el-date-picker>
          </vm-search>

          <vm-search label="操作类型">
            <vm-dict-select v-model="filter.operateType" type="operationType"></vm-dict-select>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="operatorName" :label="$l('user.operatorName','姓名')" align="center">
        <template slot-scope="scope">
          {{scope.row.operatorName?scope.row.operatorName:'系统'}}
        </template>
      </el-table-column>
      <el-table-column prop="operatedTime" :label="$l('user.time','操作时间')" align="center"></el-table-column>
      <el-table-column prop="operateTypeDesc" :label="$l('user.time','操作类型')" align="center">
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";

  export default {
    components:{EditPage},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
          orderColumnName:"operatedTime",
        },
        option:[{
                  label:"是",
                  value:true,
                },
                {
                  label: "否",
                  value: false,
                },
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`users/${row.id}/toggleStatus`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`users/${row.id}/resetPassword?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.operatorName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("authentication/user-login-log/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.operatorName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
