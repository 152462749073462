<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('user.edit','新增登录日志'):$l('user.add','新增用户')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('user.labelWidth','80px')">
      <el-form-item label="登陆人" prop="userId">
        <vm-select-input
          :value="model.userName"
          @select="$refs.userSelect.open();"></vm-select-input>
      </el-form-item>

      <el-form-item :label="$l('user.name','登录ip')" prop="name">
        <el-input v-model="model.loginIp" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item :label="$l('user.name','登录时间')" prop="loginTime">
        <el-date-picker
          v-model="model.loginTime"
          type="datetime"
          placeholder="选择登录时间"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>

      <el-form-item :label="$l('user.type','是否登陆成功')" prop="succeed" :rules="$rule.notNull">
        <el-select v-model="model.succeed" placeholder="请选择">
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$l('user.name','登录失败备注')" prop="failedRemark">
        <el-input v-model="model.failedRemark" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelect" @select="selectUserWb"></user-select>
  </el-dialog>
</template>

<script>
  import loginUtil from "@/util/loginUtil";
  import UserSelect from "@/views/user/UserSelect";


  export default {
    components: {UserSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        roles: [],
        currUserType: loginUtil.getUserType(),
        showUserTypes: null,
        model: {
          id: 0,
          phone: "",
          password: "",
          name: "",
          userType: "",
          storeId: "",
          storeName: "",
          clientId: "",
          clientName: "",
        },
        option:[{
                  label:"是",
                  value:true,
                },
                {
                  label: "否",
                  value: false,
                },
        ],
      };
    },
    methods: {
      open(id, userType) {
        this.model.id = id;
        if (this.currUserType === 2) {
          this.showUserTypes = [3, 4];
        } else if (this.currUserType === 3) {
          if (userType === 3) {
            this.$message.error("无权限编辑");
            return;
          }
          this.showUserTypes = [4];
          this.model.userType = 4;
        }
        this.dialogVisible = true;
        this.getData();
      },
      async getData() {
        this.contentLoading = true;
        this.roles = await this.$http.get("authentication/role/list");
        if (this.model.id) {
          this.model = await this.$http.get(`authentication/user-login-log/${this.model.id}`);

        } else {
          if (this.currUserType === 3) {
            this.model.storeName = loginUtil.getStoreName();
            this.model.storeId = -1;
          }
        }
        this.contentLoading = false;
      },
      storeSelect(row) {
        this.model.storeId = row.id;
        this.model.storeName = row.storeName;
      },
      selectUserWb(row) {
        this.$set(this.model,"userId",row.id);
        this.$set(this.model,"userName",row.name);
      },
      clientSelect(row) {
        this.model.clientId = row.id;
        this.model.clientName = row.clientName;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;

            this.$http
              .save("authentication/user-login-log", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
